import Importer from './importer';
import Papa from 'papaparse';

/**
 * The class representing a Csv importer
 *
 * @class
 */
export
   default class CsvImporter extends Importer {

   static import(source) {
      return new Promise((resolve, reject) => {
         Papa.parse(source, {
            skipEmptyLines: true,
            complete: results => resolve(results.data),
            error: error => reject(error)
         });
      });
   }
}