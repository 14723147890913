/**
 * An interface representing an exportable object
 *
 * @interface
 */
export
   default class Exporter {
      /* jshint ignore:start */
      static export(source, target) {
         throw new Error(`The ${arguments.callee.name} function must be overridden`);
      }
      /* jshint ignore:end */
   }