import CsvImporter from './importers/csv-importer';
import CsvExporter from './exporters/csv-exporter';

/**
 * Pads a number
 *
 * @param {number}
 *
 * @return {string}
 */
function padNumber(number) {
   return (number < 10 ? '0' : '') + number;
}

/**
 * @param {MotionEvent[]} motionEvents
 */
export

 function exportToCsv(motionEvents) {
   const now = new Date();
   const fileName = 'clia ' +
      `${padNumber(now.getDate())}-${padNumber(now.getMonth() + 1)}-${now.getFullYear()} ` +
      `${padNumber(now.getHours())}-${padNumber(now.getMinutes())}.csv`;
   const sortedMotionEvents = Array
      .from(motionEvents)
      .sort((event1, event2) => event1.ref.localeCompare(event2.ref));
   const downloadLink = document.createElement('a');

   downloadLink.setAttribute('href', encodeURI(CsvExporter.export(sortedMotionEvents)));
   downloadLink.setAttribute('download', fileName);

   // Firefox does not download the file unless the anchor element
   // is added to the page. This might be caused by this issue:
   // https://bugzilla.mozilla.org/show_bug.cgi?id=874009
   document.body.appendChild(downloadLink);

   downloadLink.click();

   document.body.removeChild(downloadLink);
}

/**
 * @param {string[]} fields
 * @param {number} index
 * @param {Object[]} set
 * @param {number} maxTimes
 *
 * @return {number}
 */
function addObjectsFromFields(fields, index, set, maxTimes) {
   for(let times = 0; times < maxTimes; times++) {
      if (fields[index] === '') {
         index = index + 3;
         continue;
      }

      set.push({
         text: fields[index++],
         category: fields[index++],
         subcategory: fields[index++]
      });
   }

   return index;
}

/**
 * @param {File} file
 *
 * @return {Promise}
 */
export

 function importFromCsv(file) {
   return CsvImporter
      .import(file)
      .then(data => {
         return data.map(fields => {
            let values = {};
            let index = 0;

            values.ref = fields[index++];
            values.text = fields[index++];

            values.adverbal = [];
            index = addObjectsFromFields(fields, index, values.adverbal, 3);

            values.adnominal = [];
            index = addObjectsFromFields(fields, index, values.adnominal, 3);

            values.verb = [];
            index = addObjectsFromFields(fields, index, values.verb, 2);

            values.noun = {
               text: fields[index++],
               category: fields[index++]
            };

            values.manner = {
               text: fields[index++],
               category: fields[index++]
            };

            values.subP = fields[index++]
               .split(', ')
               .filter(Boolean);

            // Ignore the construction field
            index++;

            values.note = fields[index++];

            return values;
         });
      });
}