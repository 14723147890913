const MAX_ADVERBAL_ENTRIES = 3;
const MAX_ADNOMINAL_ENTRIES = 3;
const MAX_VERB_ENTRIES = 2;
const CELLS_PER_FIELD = 3;

const defaults = {
   ref: '',
   text: '',
   adverbal: [],
   adnominal: [],
   verb: [],
   noun: {
      text: '',
      category: ''
   },
   manner: {
      text: '',
      category: ''
   },
   subP: [],
   note: ''
};

function propertyToString(property) {
   let string = '';

   if (property instanceof Array) {
      string = property
         .map(entry => entry.category + entry.subcategory)
         .join(' ');
   } else if (property.text) {
      string = property.category;
   }

   return string;
}

export
 default class MotionEvent {
   constructor(values = {}) {
      Object.assign(this, defaults, values);
      Object.defineProperty(this, 'construction', {
         enumerable: true,
         configurable: false,
         get() {
            let construction = '';

            construction += propertyToString(this.adverbal) + ' ';
            construction += propertyToString(this.adnominal) + ' ';
            construction += propertyToString(this.verb) + ' ';
            construction += propertyToString(this.noun) + ' ';
            construction += propertyToString(this.manner) + ' ';

            construction = construction
               .replace(/ +/g, ' ')
               .trim();

            if (this.subP.length > 0) {
               construction += `; ${this.subP.join(', ')}`;
            }

            return construction;
         }
      });
   }

   toCsv(delimiter = ',', enclosure = '"') {
      let cols = [];
      let missingFields = 0;

      cols.push(this.ref, this.text);

      this.adverbal.forEach(entry => cols.push(entry.text, entry.category, entry.subcategory));
      missingFields = (MAX_ADVERBAL_ENTRIES - this.adverbal.length) * CELLS_PER_FIELD;

      if (missingFields > 0) {
         cols.push(...new Array(missingFields).fill(''));
      }

      this.adnominal.forEach(entry => cols.push(entry.text, entry.category, entry.subcategory));
      missingFields = (MAX_ADNOMINAL_ENTRIES - this.adnominal.length) * CELLS_PER_FIELD;

      if (missingFields > 0) {
         cols.push(...new Array(missingFields).fill(''));
      }

      this.verb.forEach(entry => cols.push(entry.text, entry.category, entry.subcategory));
      missingFields = (MAX_VERB_ENTRIES - this.verb.length) * CELLS_PER_FIELD;

      if (missingFields > 0) {
         cols.push(...new Array(missingFields).fill(''));
      }

      cols.push(
         this.noun.text,
         this.noun.category,
         this.manner.text,
         this.manner.category,
         this.subP.join(', '),
         this.construction,
         this.note
      );

      return `${enclosure}${cols.join(`${enclosure}${delimiter}${enclosure}`)}${enclosure}`;
   }
}