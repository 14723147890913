const defaults = {
   container: document.body,
   content: '',
   showClass: 'is-shown',
   type: 'success',
   typeClassPrefix: 'notification--'
};
const jsHooks = {
   close: 'js-notification-close',
   content: 'js-notification-content',
   notification: 'js-notification'
};

export
 default class Notification {
   constructor(templateSelector, settings = {}) {
      Object.assign(this, defaults, settings);

      this.element = document
         .querySelector(templateSelector)
         .content
         .cloneNode(true)
         .querySelector(`.${jsHooks.notification}`);
   }

   init() {
      this.element
         .querySelector(`.${jsHooks.content}`)
         .innerHTML = this.content;
      this.element
         .querySelector(`.${jsHooks.close}`)
         .addEventListener('click', () => this.hide());
      this.container.appendChild(this.element);

      return this;
   }

   show(duration = -1) {
      // The call to getComputedStyle is necessary because the animation
      // might run on a potentially new (just) added element.
      // More info can be found here: http://stackoverflow.com/a/24195559/707795
      window.getComputedStyle(this.element).opacity; // jshint ignore:line
      this.element.classList.add(this.showClass, `${this.typeClassPrefix}${this.type}`);

      if (duration >= 0) {
         setTimeout(() => this.hide(), duration);
      }

      return this;
   }

   hide() {
      const typePrefixRegex = new RegExp(`\b${this.typeClassPrefix}.+?\b`);
      const deleteNotification = function() {
         this.element.removeEventListener('transitionend', deleteNotification);
         this.container.removeChild(this.element);
      }.bind(this);

      this.element.classList.remove(this.showClass);
      this.element.className = this.element.className.replace(typePrefixRegex, '');
      this.element.addEventListener('transitionend', deleteNotification);

      return this;
   }
}