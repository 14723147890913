import Exporter from './exporter';

/**
 * The class representing a Csv exporter
 *
 * @class
 */
export
   default class CsvExporter extends Exporter {

   static export(source, target) { // jshint ignore:line
      const csvMimeType = 'data:text/csv;charset=utf-8,';

      return csvMimeType + source
         .map(entry => entry.toCsv())
         .join('\n');
   }
}