import serialize from 'form-serialize';
import MotionEvent from './motion-event';
import Notification from './notification';
import {importFromCsv, exportToCsv} from './csv';
import adverbalCategories from '../views/data/adverbal.json';
import adnominalCategories from '../views/data/adnominal.json';
import verbCategories from '../views/data/verb.json';

const jsHooks = {
   addTarget: 'js-add-target',
   addTrigger: 'js-add-trigger',
   category: 'js-category',
   deleteTarget: 'js-delete-target',
   deleteTrigger: 'js-delete-trigger',
   exportData: 'js-export',
   field: 'js-field',
   form: 'js-form',
   importData: 'js-import',
   notificationWrapper: 'js-notification-wrapper',
   subcategory: 'js-subcategory',
   template: 'js-template'
};
const classNames = {
   hidden: 'hidden'
};
const $ = selector => {
   return Array.from(document.querySelectorAll(selector));
};

const form = $(`.${jsHooks.form}`)[0];
const notificationsWrapper = $(`.${jsHooks.notificationWrapper}`)[0];
let motionEvents = [];

/**
 * @param {string[]} values
 *
 * @return {string}
 */
function createOptions(values) {
   return values
      .map(value => `<option value="${value}">${value}</option>`)
      .join('');
}

/**
 * Updates the subcategories available based on the category selected by the user
 *
 * @param {HTMLelement} category The element containing the categories
 */
function updateSubcategories(category) {
   const requiredAttribute = 'required';
   const selectedCategory = category.dataset.category;
   const subcategoryElement = category
      .closest(`.${jsHooks.template}`)
      .querySelector(`.${jsHooks.subcategory}`);
   let categories;

   if (selectedCategory === 'adverbal') {
      categories = adverbalCategories;
   } else if (selectedCategory === 'adnominal') {
      categories = adnominalCategories;
   } else if (selectedCategory === 'verb') {
      categories = verbCategories;
   }

   let subcategories = categories[category.value];

   if (subcategories.length === 0) {
      subcategoryElement.classList.add(classNames.hidden);
      subcategoryElement.innerHTML = '';
      subcategoryElement.removeAttribute(requiredAttribute);
   } else {
      subcategoryElement.classList.remove(classNames.hidden);
      subcategoryElement.innerHTML = createOptions(subcategories);
      subcategoryElement.setAttribute(requiredAttribute, '');
   }
}

/**
 * @param {HTMLelement} element
 */
function cloneEntry(element) {
   const root = element.closest(`.${jsHooks.field}`);
   const container = document.createElement('div');
   const target = root.querySelector(`.${jsHooks.addTarget}`);

   container.innerHTML = root.querySelector(`.${jsHooks.template}`).outerHTML;
   container
      .querySelector(`.${jsHooks.deleteTrigger}`)
      .classList
      .remove(classNames.hidden);
   updateSubcategories(container.firstElementChild.querySelector(`.${jsHooks.category}`));
   target.appendChild(container.firstElementChild);
}

/**
 * Resets the form to its initiale state
 *
 * @param {HTMLelement} form
 */
function resetForm(form) {
   form.reset();
   $(`.${jsHooks.addTarget}`).forEach(element => element.innerHTML = '');
   $(`.${jsHooks.category}`).forEach(category => updateSubcategories(category));
}

/**
 * @param {Object|Object[]} formEntry
 * @param {string} fieldName
 *
 * @return {Object|Object[]}
 */
function fieldToProperty(formEntry, fieldName) {
   let data;

   if (formEntry[`${fieldName}-text`] instanceof Array) {
      data = [];

      formEntry[`${fieldName}-text`].forEach((value, index) => {
         if (formEntry[`${fieldName}-text`][index]) {
            data.push({
               text: formEntry[`${fieldName}-text`][index],
               category: formEntry[`${fieldName}-category`][index],
               subcategory: formEntry[`${fieldName}-subcategory`][index]
            });
         }
      });

      data.sort((v1, v2) => v1.category.localeCompare(v2.category));
   } else {
      data = {};

      if (formEntry[`${fieldName}-text`]) {
         data.text = formEntry[`${fieldName}-text`];
         data.category = formEntry[`${fieldName}-category`];
      }
   }

   return data;
}

function getMotionEventData(form) {
   const formEntry = serialize(form, {
      hash: true,
      empty: true
   });

   return {
      ref: formEntry.ref,
      text: formEntry.text,
      adverbal: fieldToProperty(formEntry, 'adverbal'),
      adnominal: fieldToProperty(formEntry, 'adnominal'),
      verb: fieldToProperty(formEntry, 'verb'),
      noun: fieldToProperty(formEntry, 'noun'),
      manner: fieldToProperty(formEntry, 'manner'),
      subP: formEntry['sub-p'].filter(Boolean),
      note: formEntry.note
   };
}

function deleteEntry(element) {
   let category = element.closest(`.${jsHooks.deleteTarget}`);

   category.parentElement.removeChild(category);
}

function updateConstruction() {
   const motionEvent = new MotionEvent(getMotionEventData(form));

   $('.js-construction')[0].innerHTML = motionEvent.construction;
}

// Bind events
form.addEventListener('change', event => {
   if (event.target.classList.contains(jsHooks.category)) {
      updateSubcategories(event.target);
   }
});
form.addEventListener('click', event => {
   if (event.target.closest(`.${jsHooks.deleteTrigger}`) !== null) {
      deleteEntry(event.target);
   }
});

['change', 'input', 'reset']
   .forEach(event => form.addEventListener(event, updateConstruction));

form.addEventListener('submit', event => {
   event.preventDefault();

   const motionData = getMotionEventData(form);
   const notification = new Notification('#notification-template', {
      container: notificationsWrapper,
      content: 'Data successfully saved'
   });

   motionEvents.push(new MotionEvent(motionData));
   notification
      .init()
      .show(3000);
   resetForm(form);
   updateConstruction();
});

$(`.${jsHooks.importData}`).forEach(element => {
   element.addEventListener('change', event => {
      importFromCsv(event.target.files[0])
         .then(values => {
            motionEvents = values.map(object => new MotionEvent(object));

            const notification = new Notification('#notification-template', {
               container: notificationsWrapper,
               content: 'Data successfully imported'
            });

            notification
               .init()
               .show(3000);
         })
         .catch(error => {
            const notification = new Notification('#notification-template', {
               container: notificationsWrapper,
               content: 'Data not imported',
               type: 'alert'
            });

            notification
               .init()
               .show(3000);

            console.debug('An error occurred:', error);
         });
   });
});
$(`.${jsHooks.exportData}`).forEach(element => {
   element.addEventListener('click', () => exportToCsv(motionEvents));
});
$(`.${jsHooks.addTrigger}`).forEach(element => {
   element.addEventListener('click', () => cloneEntry(element));
});

updateConstruction();

Array
   .from(form.querySelectorAll(`.${jsHooks.category}`))
   .forEach(element => updateSubcategories(element));