/**
 * An interface representing an importable object
 *
 * @interface
 */
export
   default class Importer {
      /* jshint ignore:start */
      static import(source) {
         throw new Error(`The ${arguments.callee.name} function must be overridden`);
      }
      /* jshint ignore:end */
   }